import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContentdv from '../components/ServiceDetailsdv/ServiceDetailsContentdv'
import RelatedServicesdv from '../components/ServiceDetailsdv/RelatedServicesdv'

const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Data Visualization  "
                homePageText="Home"
                homePageUrl="/"
                activePageText="Service Details"
            />
            <ServiceDetailsContentdv />
            <RelatedServicesdv />
            <Footer />
        </Layout>
    );
}

export default Details