import React from 'react'
import ServiceSidebardv from './ServiceSidebardv'
import detailsdv from '../../assets/images/services/services-detailsdv.jpg'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContentdv = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={detailsdv} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Data Visualization </span>
                            <h3>About this Service</h3>
                            <p>Get data-driven business insights through our dynamic data visualization solutions built on a modern, flexible, infrastructure. </p>
                            <p>Data Visualization, also known as data exploration or ad hoc Business Intelligence, is often referred to as dashboarding. The data visualization industry has garnered a great deal of attention over recent years because of the easy-to-understand format that complex data is delivered in.</p>
                            <p>Many experts have broken data visualization out as an area that should be looked at on its own merit outside of Enterprise Business Intelligence (EBI). This is largely due to requirements that point to quick data analysis and fast turnaround for useful information in an efficient way. Many organizations deploy these tools departmentally and (often) very specifically with a strategy for long-term impact in mind.</p>
                           
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Important Facts</h3>
                                        <ul>
                                            <li>25+ Implementations</li>
                                            <li>All market leading DV Tools</li>
                                            <li>15+ years of experience</li>
                                            

                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>We assist companies in rolling out a dashboarding approach or weaving it into an existing Enterprise Business Intelligence system.</p>
                            <p>Making the business successful should be the guiding principle for any deployment. Data visualization allows companies to roll out tools for employees to explore comprehensive data quickly. This is a very real need and Diamonds Consulting  can provide a strategy that is aligned with goals of your organization to ensure the success of a data visualization project.</p>
                            <h3>Application Areas</h3>
                            <p> Related Industries/Application Domains</p>
                            <div className="row">
                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-hospital"></i>
                                    </div>
                                        Insurance
                                    </div>
                            </div>



                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-investment"></i>
                                    </div>
                                        Logistics
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Manufacturing
                                    </div>
                            </div>

                            <div className="col-lg-4 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-house"></i>
                                    </div>
                                        Health Care

                                    </div>
                            </div>
                            <div className="col-lg-3 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-factory"></i>
                                    </div>
                                        Banking
                                    </div>
                            </div>
                            <div className="col-lg-5 col-sm-6 col-md-6">
                                <div className="single-industries-serve-box">
                                    <div className="icon">
                                        <i className="flaticon-tracking"></i>
                                    </div>
                                        Telecommunications
                                    </div>
                                </div>
                            </div>

                            <h3>Technologies That We Use</h3>
                            <ul className="technologies-features">
                                <li><span>Oracle</span></li>
                                <li><span>Teradata</span></li>
                                <li><span>DB2</span></li>
                                <li><span>Netezza</span></li>
                                <li><span>MS Azure</span></li>
                                <li><span>AWS</span></li>
                                <li><span>Tableau</span></li>
                                <li><span>OAC</span></li>
                                <li><span>Qlikview</span></li>
                                <li><span>Microstrategy</span></li>
                                <li><span>BusinessObjects</span></li>
                                <li><span>MS Power BI</span></li>
                            </ul>
                            <div className="charts-image">
                                <img src={charts} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebardv />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContentdv